import React from 'react';

import './Footer.scss';

const entity = '© 2025 Digital Asset Holdings, LLC';
const links: { link: string; label: string }[] = [
    {
        label: 'Warning - Phishing & Scams',
        link: 'https://www.digitalasset.com/phishing-warning?hsLang=en',
    },
    {
        label: 'Privacy Policy',
        link: 'https://www.digitalasset.com/privacy-policy?hsLang=en',
    },
    {
        label: 'Responsible Disclosure Policy',
        link: 'https://www.digitalasset.com/responsible-disclosure?hsLang=en',
    },
    {
        label: 'Trademark Policy',
        link: 'https://www.digitalasset.com/trademark-policy?hsLang=en',
    },
    {
        label: 'Terms & Conditions',
        link: 'https://hub.daml.com/terms',
    },
    {
        label: 'Contact Support',
        link: ' https://www.digitalasset.com/contact-support?hsLang=en',
    },
];

const createGroups = (links: { link: string; label: string }[]) => {
    const groups = [];
    let group: JSX.Element[] = [];
    links.forEach(link => {
        group.push(
            <a key={link.label + link.link} href={link.link}>
                {link.label}
            </a>
        );
        if (group.length === 2) {
            groups.push(group);
            group = [];
        }
    });
    if (group.length) {
        groups.push(group);
    }
    return groups;
};

export const Footer: React.FC = () => {
    const linkGroups = createGroups(links);
    const groups = linkGroups.map((linkGroup, i) => (
        <div key={i + linkGroup[0].props} className="link-group">
            {linkGroup}
        </div>
    ));

    return (
        <footer className="footer">
            <div className="entity">{entity}</div>
            <div className="links">{groups}</div>
        </footer>
    );
};
